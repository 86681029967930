import React from 'react';
import "./assets/item.css";

function Item({ nome }) {

  return (
    
    <div className={("selector-" + nome).replace("-select","")}>
      <img
        className="button-img"
        src={require(`./assets/images/${nome}.svg`)}
        alt={nome}
      />
    </div> 
    
  );
}

export default Item;
