import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
//import video from "./assets/videos/background.mp4";
import ArrowButton from "../../components/ArrowButton/ArrowButton";

function Main() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Main-" + language;
  const text = idiomData[selectedLanguage];
  return (
    <>
      <div className="video-background">
        <video autoPlay muted loop>
          {//<source src={video} type="video/mp4" />
          }
        </video>
      </div>

      <div className="main">
        <div className="main-title">
          <h1>
            {" "}
            {text.Title["0"]}
            <a className="main-title-weonne ">{text.Title["1"]}</a>
            {text.Title["2"]}
            <a className="main-title-result">{text.Title["3"]}</a>
            {text.Title["4"]}
          </h1>
        </div>
        <h2 className="main-text"> {text.Content}</h2>
        <a className="mainButton"><ArrowButton /></a>
      </div>

 
    </>
  );
}

export default Main;
