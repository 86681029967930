import React, {useContext} from "react";
import "./styles.css";
import emailjs from "emailjs-com";
import idiomData from "../IdiomSelector/IdiomSelector";
import { LanguageContext } from "../Language/Language";



 
function Mailer() { 
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Contact-" + language;
  const text = idiomData[selectedLanguage];

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,process.env.REACT_APP_USER_ID
      
    ).then(() => {
      document.getElementById("emailForm").style.display=("none")
      document.getElementById("success").style.display=("flex")
    }, (error) => {
      console.log(error.text);
  });
  e.target.reset();
  }
  return (
    <>
      <h1 className="mailer-title">{text.Form["Title"]}</h1>
      <form id="emailForm" className="emailForm" onSubmit={sendEmail}>
        <input type="text" name="name" placeholder={text.Form["field_1"]} />
        <input type="text" name="telephone" placeholder={text.Form["field_2"]} />
        <input type="email" name="email" placeholder={text.Form["field_3"]} />
        <textarea name="message" placeholder={text.Form["field_4"]}></textarea>
        <button className="mailer-submit" type="submit">
          {text.Form["button"]} ➜
        </button>
      </form>
      <h1 className="success" id="success" style={{display:"none"}}>{text.Form["success_1"]}<br/> {text.Form["success_2"]}</h1>
    </>
  );
}

export default Mailer;
