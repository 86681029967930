import React from 'react'
import orbitAnt from "./assets/images/orbit-1.svg";
import orbitHor from "./assets/images/orbit-2.svg";
import './assets/orbits.css'

function Orbit() {
  return (
    <div className="orbits">
      <img src={orbitHor} alt="orbitHor" className="orbitHor" />
      <img src={orbitAnt} alt="orbitAnt" className="orbitAnt" />
    </div>
  );
}

export default Orbit;
