import React, { useEffect } from "react";
import arrow from "./assets/images/arrow.svg";
import "./styles.css";

function ArrowButton() {
  useEffect(() => {
    const movingImage = document.getElementById("moving-image");

    function moveImageUpAndDown() {
      movingImage.classList.toggle("down");
    }

    const intervalId = setInterval(moveImageUpAndDown, 1000);

    // Limpa o intervalo quando o componente for desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const scrollDown = () => {
    const targetPosition = window.innerHeight * 0.8; // Posição de destino para rolagem
    const currentPosition = window.pageYOffset; // Posição atual de rolagem
    const distance = targetPosition - currentPosition; // Distância a ser percorrida
    const duration = 1000; // Duração da animação em milissegundos
    const startTime = performance.now(); // Tempo inicial da animação
  
    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime; // Tempo decorrido desde o início da animação
      const progress = Math.min(elapsedTime / duration, 1); // Progresso da animação (entre 0 e 1)
      const easeProgress = easeOutQuad(progress); // Função de interpolação para suavizar a animação
      const newPosition = currentPosition + distance * easeProgress; // Nova posição de rolagem
  
      window.scrollTo(0, newPosition);
  
      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll); // Chama novamente a função na próxima atualização de quadro
      }
    };
  
    requestAnimationFrame(animateScroll);
  };
  
  // Função de interpolação para suavizar a animação
  const easeOutQuad = (t) => t * (2 - t);
  
  

  return (
    <a id="moving-image" className="arrowButton" onClick={scrollDown}>
      <img  className="arrow" src={arrow} alt="Seta"></img>
    </a>
  );
}

export default ArrowButton;
