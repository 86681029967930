import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";


function importAll(r) {
  const images = {};
  r.keys().forEach((key) => {
    const imageName = key.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "");
    images[imageName] = r(key);
  });
  return images;
}

const images = importAll(
  require.context("./assets/images/", false, /\.(png|jpe?g|svg)$/)
);

function Owners() {
  const { language } = useContext(LanguageContext)
  const selectedLanguage = "Owners-" + language
  const text = idiomData[selectedLanguage]
  return (
    <>
      <div className="owners-block">
        {Object.keys(text).map((key) => (
          <a className="owners-link" href={text[key].link} target="_blank">
          <div className="owner-block title" key={key}>
            <img className="owners-img" src={images[text[key].Image]} alt={text[key].image}/>
            <h1>{text[key].Title}</h1>
            <pre>{text[key].Content}</pre>
            <div className="owners-lindekin"></div>
          </div>
          
          </a>
        ))}
      </div>
    </>
  );
}

export default Owners;
