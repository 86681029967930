import React, { useEffect } from "react";
import arrow from "./assets/images/arrow.svg";
import "./styles.css";

function ArrowLeft() {


  

  return (
    <a id="moving-imageleft" className="arrowButtonleft">
      <img  className="arrowleft" src={arrow} alt="Seta"></img>
    </a>
  );
}

export default ArrowLeft;
