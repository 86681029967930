import React, { useRef, useContext, useState } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
import up from "./assets/images/up.png";
import down from "./assets/images/down.png";

function importAll(r) {
  const images = {};
  r.keys().forEach((key) => {
    const imageName = key.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "");
    images[imageName] = r(key);
  });
  return images;
}

const images = importAll(
  require.context("./assets/images/", false, /\.(png|jpe?g|svg)$/)
);

function Expertises() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Expertises-" + language;
  const text = idiomData[selectedLanguage];

  const [isExpanded, setIsExpanded] = useState(false);

  function showList() {
    setIsExpanded((prevState) => !prevState);
    const exp = document.getElementById("expertises");
    if (exp.style.height === "100%") {
      exp.style.height = "500px";
    } else {
      exp.style.height = "100%";
    }
  }

  window.addEventListener("scroll", function () {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;

    title.forEach(function (text) {
      const textPosition = text.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        text.classList.add("appear");
      }
    });
    content.forEach(function (content) {
      const textPosition = content.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        content.classList.add("appear");
      }
    });
  });

  const containerRef = useRef(null);

  return (
    <>
      <div className="titleBlock">
        <h1 className="firstTitle title">{text.Title}</h1>
        <h1 className="secondTitle title">{text.Content}</h1>
      </div>
      <div className="expertises-block">
        <div id="expertises" className="expertises" ref={containerRef}>
          {Object.keys(text.List).map((key, index) => (
            <div className="expertise-block title append" key={key}>
              <h1 className="">
                <img
                  src={images[text.List[key].Image]}
                  alt={text.List[key].Image}
                />
                {text.List[key].Title}
              </h1>
              <h2 className="">{text.List[key].Content}</h2>
            </div>
          ))}
        </div>
      </div>
      <button
        id="expertises-button"
        className="expertises-button"
        onClick={showList}
      >
        <a id="expertises-more">{isExpanded ? "ver menos" : "ver mais"}</a>
        <img
          id="expertises-img"
          className="expertises-img"
          src={isExpanded ? up : down}
          alt="Arrow"
        />
      </button>
    </>
  );
}

export default Expertises;
