import Header from "./components/Header/Header";
import Main from "./pages/Main/Main";
import Expertises from "./pages/Expertises/Expertises";
import Info from "./pages/Info/Info";
import Footer from "./components/Footer/Footer";
import Owners from "./pages/Owners/Owners";
import Contact from "./pages/Contact/Contact";
import Partners from "./pages/Partners/Partners";
import Strategy from "./pages/Strategy/Strategy";
import Results from "./pages/Results/Results";

import React from "react";
import { LanguageProvider } from "./components/Language/Language";

function App() {
  return (
    <LanguageProvider>
      <Header />
      <Main />
      <Strategy />
      <Results />
      <Expertises />
      <Info />
      <Owners />
      <Partners />
      <Contact />
      <Footer />
    </LanguageProvider>
  );
}

export default App;
