import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
import seastorm from "./assets/images/seastorm.png";
import PartnersData from './partners.json'
import map from './assets/images/map.png'

function importAll(r) {
  const partnersImage = {};
  r.keys().forEach((key) => {
    const imageName = key.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "");
    partnersImage[imageName] = r(key);
  });
  return partnersImage;
}

const partnersImages = importAll(
  require.context("./assets/images/partners", false, /\.(png|jpe?g|svg)$/)
);

function importAll2(r) {
  const contry = {};
  r.keys().forEach((key) => {
    const imageName = key.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "");
    contry[imageName] = r(key);
  });
  return contry;
}

const contry = importAll2(
  require.context("./assets/images/contry", false, /\.(png|jpe?g|svg)$/)
);

window.addEventListener('scroll', function() {
  const title = document.querySelectorAll(".title");
  const content = document.querySelectorAll(".content");
  const screenHeight = window.innerHeight;

  title.forEach(function(text) {
    const textPosition = text.getBoundingClientRect().top;

    if (textPosition < screenHeight) {
      text.classList.add('appear');
    }
  });
  content.forEach(function(content) {
    const textPosition = content.getBoundingClientRect().top;

    if (textPosition < screenHeight) {
      content.classList.add('appear');
    }
  });
});

function Partners() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Partners-" + language;
  const text = idiomData[selectedLanguage];

  return (
    <div className="partners-block">
      <a className="partners-image" href={(PartnersData["seastorm"])} target="_blank">
      <img className="partners-image title" src={seastorm} />
      </a>
      <div className="partners-text content">{text.Content}</div>
      
      <div className="partners-images-contrys">
        {Object.keys(contry).map((image) => (
          <img className="partners-contry title" src={contry[image]} />
        ))}
      </div>
      <div className="partners-images">
        {Object.keys(partnersImages).map((image) => (
           <a className="partners-images-company content" href={(PartnersData[image])} target="_blank">
          <img src={partnersImages[image]} alt={image}/>
           
          </a>
        ))}
      </div>
      <img className="map" src={map}/>
    </div>
  );
}

export default Partners;
