import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
import SelectorMenu from "../../components/SelectorMenu/SelectorMenu";

function Results() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Results-" + language;
  const text = idiomData[selectedLanguage];

  window.addEventListener('scroll', function() {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;
  
    title.forEach(function(text) {
      const textPosition = text.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        text.classList.add('appear');
      }
    });
    content.forEach(function(content) {
      const textPosition = content.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        content.classList.add('appear');
      }
    });
  });

  return (
    <div className="results-block">
      <div className="titleBlock">
        <h1 className="firstTitle title" >{text.Title}</h1>
        <h2 className="results-title2 secondTitle title">{text.Content}</h2>
      </div>

      <SelectorMenu />
    </div>
  );
}

export default Results;
