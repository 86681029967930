function importAll(r) {
  const idiomData = {};
  r.keys().forEach((key) => {
    const idiomKey = key.replace(/\.\/(.+)\//, '$1').replace("pt.json", "-pt").replace("es.json", "-es").replace("en.json", "-en");
    idiomData[idiomKey] = r(key);
  });
  return idiomData;
}

const idiomData = importAll(require.context('../../assets/idiom', true, /\.json$/));

export default idiomData;
