import React, { useEffect } from "react";
import "./styles.css";
import logo from "../../assets/images/header-logo.png";
 import LanguageSelector from './LanguageSelector'
function Header() {
  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const menu = document.getElementById("menu");
    
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        menu.style.top = "0";
      } else {
        menu.style.top = "-100px";
      }
      if(currentScrollPos<200){
        menu.style.top = "0";
      }
      
      {prevScrollPos = currentScrollPos;}
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header-container" id="menu">
      <a href="/">
        <img className="logo-image" src={logo} alt="Logo" />
      </a>
      <div className="language-selector">
        
      </div>
      <LanguageSelector />
    </div>
  );
}

export default Header;
