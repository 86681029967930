import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
import down from "./assets/images/down.png";
import up from "./assets/images/up.png";

function Info() {

  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Info-" + language;
  const text = idiomData[selectedLanguage];

  function showList(index) {
    const title = document.getElementById("info-" + index);
    const img = document.getElementById("img-" + index);
    const block  = document.getElementById("info-block-"+index);
    const content = document.getElementById("info-title-"+index)
    for(let c=0;c<4; c++){
      if(c===index) {
      img.src = up;
      title.style.display = "block";
      title.style.color="white";
      content.style.color="white";
      block.style.minHeight = "250px"
      block.style.backgroundColor="#232323"
      
    } else {
      const title2 = document.getElementById("info-" + c);
      const img2 = document.getElementById("img-" + c);
      const block2  = document.getElementById("info-block-"+c);
      const content2  = document.getElementById("info-title-"+c);
      img2.src = down;
      title2.style.display = "none";
      title.style.color="white";
      block2.style.minHeight = "100px"
      content2.style.color="#021032";
      block2.style.backgroundColor="#fbfbfb"
      
    }}
  }

  window.addEventListener('scroll', function() {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;
  
    title.forEach(function(text) {
      const textPosition = text.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        text.classList.add('appear');
      }
    });
    content.forEach(function(content) {
      const textPosition = content.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        content.classList.add('appear');
      }
    });
  });

  return (
    <>
     <div className="infos-title titleBlock">
        <h1 className="firstTitle title">{text.Title}</h1>
        <h1 className="secondTitle title">{text.Content}</h1>
      </div>
    <div className="infos-block">
      {Object.keys(text.info).map((key, index) => (
        
        <div className="info-block" id={"info-block-"+ index} key={key} >
          <a className="info-block-button" onClick={()=>showList(index)}/>
          <h1 className="info-title title" id={"info-title-"+index}>{text.info[key].Title}</h1>

          <button
            onClick={() => showList(index)}
            id={"button-" + index}
            class="toggle-button"
          >
            <img id={"img-" + index} src={down} />
          </button>
          <a className="info-abutton" onClick={() => showList(index)}></a>
          <p id={"info-" + index} className="content">{text.info[key].Content}</p>
        </div>
      
      ))}
    </div></>
  );
}

export default Info;
