import React, { useContext, useState, useRef, useEffect } from "react";
import "./assets/slider.css";
import idiomData from "../../IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../Language/Language";
import { StateContext, StateProvider } from "../MenuState";
import ArrowLeft from "./ArrowLeft/ArrowLeft";
import ArrowRight from "./ArrowRight/ArrowLeft";

function Slider() {
  const { menuState, setMenuState } = React.useContext(StateContext);

  useEffect(() => {
    move();
    blur();
    underLine();
  }, [menuState]);

  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Results-" + language;
  const text = idiomData[selectedLanguage];
  const containerRef = useRef(null);
  const slides = Object.values(text.Menus);

  // const [newState, SetNewState] = useState(0);
  //const [newPosition, SetNewPos] = useState(0);

  let startX = 0;
  let endX = 0;
  function underLine() {
    for (var a = 0; a < 4; a++) {
      const underLineItem = document.getElementById("under" + a);
      if (a !== menuState) {
        underLineItem.style.borderBottom = "none";
      } else {
        underLineItem.style.borderBottom = "1px solid #C42173";
      }
    }
  }
  function blur() {
    for (var a = 0; a < 4; a++) {
      const bluredItem = document.getElementById("blur" + a);
      if (a !== menuState) {
        bluredItem.style.filter = "blur(2px) opacity(0.5)";
        bluredItem.style.background= "linear-gradient(90deg, #6D7687, transparent)"
        bluredItem.style.webkitBackgroundClip = "text"
        bluredItem.style.webkitTextFillColor="transparent"
        bluredItem.style.color="#6D7687"
      } else {
        bluredItem.style.filter = "none";
        bluredItem.style.background= "none"
        bluredItem.style.webkitBackgroundClip = "none"
        bluredItem.style.webkitTextFillColor=""
        bluredItem.style.color="#6D7687"
      }
    }
  }
  function move() {
    const container = document.getElementById("slider-handle");
    container.style.left = -menuState * 530 + "px";
  }

  function handleMouseDown(event) {
    startX = event.clientX;
  }
  function handleTouchDown(event) {
    console.log(event.touches[0].clientX);
    startX = event.touches[0].clientX;
  }

  function handleTouchUp(event) {
    const difference = endX - startX;
    if (difference < -100) {
      console.log(">");
      let a = menuState + 1;
      if (a >= 4) {
        a = 0;
      }
      setMenuState(a);
    } else if (difference > 100) {
      console.log("<");
      let a = menuState - 1;
      if (a < 0) {
        a = 1;
      }
      setMenuState(a);
    }
  }
  function handleTouchMove(event) {
    endX = event.touches[0].clientX;
  }

  function handleMouseUp(event) {
    endX = event.clientX;
    const difference = endX - startX;
    if (difference < 0) {
      let a = menuState + 1;
      if (a >= 4) {
        a = 0;
      }
      setMenuState(a);
    } else if (difference > 0) {
      let a = menuState - 1;
      if (a < 0) {
        a = 1;
      }
      setMenuState(a);
    }
  }

  return (
    <div className="slider">
      {menuState<1 || <a className="SliderArrowLeft" onClick={()=>{let a=menuState-1; setMenuState(a)}}><ArrowLeft/></a>}
      {menuState>2 || <a className="SliderArrowRight" onClick={()=>{let a=menuState+1; setMenuState(a)}}><ArrowRight/></a>}
      
      <div
        className="slider-handle"
        id="slider-handle"
        ref={containerRef}
        onTouchMove={handleTouchMove}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchUp}
        onMouseUp={handleMouseUp}
      >
        {slides.map((menu, index) => (
          <div className="slider-text" key={index} id={"blur" + index}>
            <h1 id={"under" + index} className="slider-title">
              <img
                className="slider-content-img"
                src={require("./assets/images/" + menu.src + ".svg")}
              ></img>
              {menu.Title}
            </h1>
            <h2 className="slider-content">{menu.Content}</h2>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default Slider;
