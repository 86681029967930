import React, { createContext, useState } from "react";

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [menuState, setMenuState] = useState(0);

  return (
    <StateContext.Provider value={{ menuState, setMenuState }}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
