import {React, useState, useContext, useEffect } from "react";
import { LanguageContext } from "../Language/Language";
import "./language.css";

function LanguageSelector() {
 

  const {language, setLanguage} = useContext(LanguageContext)
  useEffect(() => {
    
    setSelectedLanguage(language)
    
   }, [language]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const toggleList = () => {
    setIsOpen(!isOpen);
    const back = document.getElementById("language-block")
    if (back.style.backgroundColor==="#ffffff")
    {
      back.style.backgroundColor= "none"
    }else{
      back.style.backgroundColor="#ffffff"
    }
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  function handleClick(idiom){
    setSelectedLanguage(idiom)
    setIsOpen(!isOpen)
    setLanguage(idiom)
  }
  return (
    <a className="language-block" id="language-block"> 
      <button onClick={toggleList}>
        {selectedLanguage || selectedLanguage}
      </button>
      {isOpen && (
        <a>
          {selectedLanguage !== "pt"
           && <div onClick={() => handleClick("pt")}>pt</div>}
          {selectedLanguage !== "es"
           && <div onClick={() => handleClick("es")}>es</div>}
          {selectedLanguage !== "en"
           && <div onClick={() => handleClick("en")}>en</div>}
        </a>
      )}
    </a>
  );
}

export default LanguageSelector;
