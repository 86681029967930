import React, { useEffect } from "react";
import arrow from "./assets/images/arrow.svg";
import "./styles.css";

function ArrowRight() {


  return (
    <a id="moving-imageright" className="arrowButtonright">
      <img  className="arrowright" src={arrow} alt="Seta"></img>
    </a>
  );
}

export default ArrowRight;
