import React, { useState, useEffect } from "react";
import "./styles.css";
import Orbit from "./components/Orbit";
import Item from "./components/Item";
import Slider from "./components/Slider";
import { StateContext, StateProvider } from "./MenuState";

function FullMenu() {
  const { menuState, setMenuState } = React.useContext(StateContext);

  const [client, setClient] = useState("client-select");
  const [squad, setSquad] = useState("squad");
  const [core, setCore] = useState("core");
  const [prod, setProd] = useState("prod");
  useEffect(() => {
    
    setMenu(menuState)
    
   }, [menuState]);

  function setMenu(menu) {
    setMenuState(menu);

    if (menu === 0) {
      setClient("client-select");
    } else {
      setClient("client");
    }
    if (menu === 1) {
      setSquad("squad-select");
    } else {
      setSquad("squad");
    }
    if (menu === 2) {
      setCore("core-select");
    } else {
      setCore("core");
    }
    if (menu === 3) {
      setProd("prod-select");
    } else {
      setProd("prod");
    }
    
  }

  return (
    <div className="selectorMenu-block">
      <div className="selectorMenu-selector">
        <Orbit />
        <div className="selectorMenu-itens">
          <div className="selectorMenu-button-client" onMouseOver={() => setMenu(0)}>
            <Item nome={client} />
          </div>
          <div className="selectorMenu-button-squad" onMouseOver={() => setMenu(1)}>
            <Item nome={squad} />
          </div>
          <div className="selectorMenu-button-core" onMouseOver={() => setMenu(2)}>
            <Item nome={core} />
          </div>
          <div className="selectorMenu-button-prod" onMouseOver={() => setMenu(3)}>
            <Item nome={prod} />
          </div>
        </div>
      </div>
      <div className="selectorMenu-content">
        <Slider/>
      </div>
    </div>
  );
}

function SelectorMenu() {
  return (
    <StateProvider>
      <FullMenu />
    </StateProvider>
  );
}

export default SelectorMenu;
