import React, { useContext } from "react";
import "./styles.css";
import "./mobile.css";
import banner from "./assets/images/banner-1.jpg";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";

function Strategy() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Strategy-" + language;
  const text = idiomData[selectedLanguage];

  window.addEventListener('scroll', function() {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;
  
    title.forEach(function(text) {
      const textPosition = text.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        text.classList.add('appear');
      }
    });
    content.forEach(function(content) {
      const textPosition = content.getBoundingClientRect().top;
  
      if (textPosition < screenHeight) {
        content.classList.add('appear');
      }
    });
  });

  return (
    <>
      <div className="strategy-block">
        <div className="strategy-text">
          <div className="titleBlock">
          <h1 className="firstTitle title" >{text.Title["0"]}</h1>
          <h1 className="secondTitle title" style={{color:"#ffffff"}}>{text.Title["1"]}</h1>
          </div>
          {" "} 
          <h2 className="strategy-content-1 content">
            {text.Content["0"]}
            <a style={{color:"#15F1F1"}}>{text.Content["1"]}</a>
            {text.Content["2"]}
            <a style={{color:"#E3167D"}}>{text.Content["3"]}</a>
            {text.Content["4"]}
          </h2>
          {" "} 
          <h2 className="strategy-content-2">{text.Content2}</h2>
        </div>
        <img className="strategy-image" src={banner} />
      </div>
    </>
  );
}

export default Strategy;
